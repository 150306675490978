export interface ApiPagination {
  limit: number;
  total: number;
}

export interface ApiPaginatedResponse<T> {
  data: T[] | [];
  hasMore: boolean;
  pagination: ApiPagination;
}

export interface ApiErrorItem {
  code: string;
  httpStatusCode: number;
  message: string;
  title: string;
  details?: string;
}

export type ApiErrorResponse = ApiErrorItem[];

export enum Product {
  PAY_IN_3 = 'pay-in-3',
  PAY_IN_4 = 'pay-in-4',
}

export type AxiosResponse = {
  data: {},
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
};
