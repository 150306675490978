import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '365px',
    height: '48px',
    background: '#232323',
    color: '#fff',
    borderRadius: '8px',
    '&.MuiButton-contained': {
      background: '#232323',
      width: '365px',
      height: '48px',
      color: '#fff',
      borderRadius: '8px',
      '&:hover': {
        background: '#2C2629',
      },
      '&.Mui-disabled': {
        opacity: 0.5
      },
    },
  },
}));

interface Props {
  disabled: boolean;
  children?: React.ReactNode;
}

const SubmitButton: FC<Props> = ({ disabled = false, children }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      className={classes.root}
      disabled={disabled}
      type="submit"
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
