import axios, { AxiosInstance } from 'axios';
import config from '../config';
import { Product } from 'src/types/api';

const shopifyPaymentApi = (product: Product): AxiosInstance => {
  return axios.create({
    baseURL: config.api[product].shopifyPaymentAppUrl,
    headers: { 'Content-Type': 'application/json', 'x-api-key': config.api[product].shopifyPaymentApiKey }
  });
};

export default shopifyPaymentApi;
