import { Product } from 'src/types/api';
import shopifyPaymentApi from '../lib/axios';
import type { MerchantApiKeyRequestBody } from '../types/MerchantApiKeyRequestBody';
import { logError, logInfo } from 'src/lib/logger';

class PaymentApi {
  async validateMerchantApiKey(scalapayApiKey: string, shopDomain: string, product: Product) {
    if (!Object.values(Product).includes(product)) {
      logError('Invalid product', {
        product,
        shopDomain,
      });
      return { status: 500 };
    }
    try {
      const requestBody: MerchantApiKeyRequestBody = {
        shopDomain,
        scalapayApiKey
      };
      const response = await shopifyPaymentApi(product).post('/admin/merchant/ready', requestBody);
      logInfo('Validated merchant api key', {
        shopDomain,
        product,
        scalapayApiKey,
        response,
      });
      return response;
    } catch (err) {
      logError('Error validating merchant api key', {
        err,
        shopDomain,
        scalapayApiKey,
      });
      return { status: 500 };
    }
  }
}

export const paymentApi = new PaymentApi();
