import { Product } from './types/api';

const config = {
  isDev: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  isProd: process.env.NODE_ENV === 'production',
  api: {
    [Product.PAY_IN_3]: {
      shopifyPaymentAppUrl: process.env.REACT_APP_SHOPIFY_PAYMENT_APP_URL ?? process.env.REACT_APP_SHOPIFY_PAYMENT_APP_URL_PAY_IN_3 ?? 'http://localhost:3001',
      shopifyPaymentApiKey: process.env.REACT_APP_SHOPIFY_PAYMENT_API_KEY ?? process.env.REACT_APP_SHOPIFY_PAYMENT_API_KEY_PAY_IN_3 ?? '',
    },
    [Product.PAY_IN_4]: {
      shopifyPaymentAppUrl: process.env.REACT_APP_SHOPIFY_PAYMENT_APP_URL_PAY_IN_4 ?? 'http://localhost:3001',
      shopifyPaymentApiKey: process.env.REACT_APP_SHOPIFY_PAYMENT_API_KEY_PAY_IN_4 ?? '',
    },
  },
  // merchantSignUpUrl: process.env.REACT_APP_MERCHANT_SIGNUP_URL ?? 'https://www.scalapay.com/vendors',
  partnerPortalDevUrl: process.env.REACT_APP_MERCHANT_DEVELOPER_URL ?? 'https://partner.scalapay.com/account/developer',
  shopifyPaymentGatewayRedirectPath: process.env.REACT_APP_PAYMENT_GATEWAY_REDIRECT_PATH ?? 'admin/settings/payments/alternative-providers',
  dataDog: {
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN ?? 'pubd9eb5c8c40f3848fc4656b3af1d1d5ab',
    site: process.env.REACT_APP_DD_SITE ?? 'datadoghq.eu',
    service: process.env.REACT_APP_DD_SERVICE ?? 'scalapay-shopify-frontend',
    env: process.env.NODE_ENV ?? 'local',
    version: process.env.REACT_APP_VERSION ?? '0.0.0',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  },
};

export type Config = typeof config;

export default config;
