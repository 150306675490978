import { datadogLogs } from '@datadog/browser-logs';
import config from '../config';

datadogLogs.init(
  config.dataDog
);

const logWarn = (message: string, object: Record<string, any> = {}) => {
  datadogLogs.logger.warn(`WARN: ${message}`, object);
};

const logInfo = (message: string, object: Record<string, any> = {}) => {
  datadogLogs.logger.info(`INFO: ${message}`, object);
};

const logDebug = (message: string, object: Record<string, any> = {}) => {
  datadogLogs.logger.debug(`DEBUG: ${message}`, object);
};

const logError = (message: string, object: Record<string, any> = {}) => {
  datadogLogs.logger.error(`ERROR: ${message}`, object);
};

export {
  logWarn, logInfo, logDebug, logError,
};
