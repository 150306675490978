import LocalizedStrings from 'react-localization';

const localization = new LocalizedStrings({
  en: {
    connectText: 'Connect your shop to Scalapay',
    apiText: 'Enter you API key from the developer page of your',
    partnerPortalText: 'Partner Portal',
    validCode: 'valid code',
    invalidCode: 'incorrect code',
    textPlaceHolder: 'enter your API key here',
    submitButtonText: 'confirm',
    newToScalapay: 'New to Scalapay',
    signupText: 'Sign up here',
    signupUrl: 'https://www.scalapay.com/it/vendi-con-scalapay?utm_campaign=Integration%20Plugin&utm_source=integration&utm_medium=plugin'
  },
  it: {
    connectText: 'Collega il tuo negozio a Scalapay',
    apiText: 'Inserisci la tua chiave API dalla pagina sviluppatore del tuo',
    partnerPortalText: 'portale partner',
    validCode: 'codice valido',
    invalidCode: 'codice errato',
    textPlaceHolder: 'inserisci la tua chiave API qui',
    submitButtonText: 'conferma',
    newToScalapay: 'Nuovo su Scalapay',
    signupText: 'Iscriviti qui',
    signupUrl: 'https://www.scalapay.com/it/vendi-con-scalapay?utm_campaign=Integration%20Plugin&utm_source=integration&utm_medium=plugin'
  },
  fr: {
    connectText: 'Connectez votre boutique à Scalapay',
    apiText: 'Entrez votre clé API dans la page Développeurs de votre',
    partnerPortalText: 'Portail Partenaire',
    validCode: 'Code valide',
    invalidCode: 'Code incorrect',
    textPlaceHolder: 'Entrez votre clé API ici',
    submitButtonText: 'Confirmer',
    newToScalapay: 'Nouveau chez Scalapay',
    signupText: 'Inscrivez-vous ici',
    signupUrl: 'https://www.scalapay.com/fr/vendre-avec-scalapay?utm_campaign=Integration%20Plugin&utm_source=integration&utm_medium=plugin'
  },
  de: {
    connectText: 'Verbinde deinen Shop mit Scalapay',
    apiText: 'Gib dein API Schlüssel auf deiner Entwickler Seite in deinem',
    partnerPortalText: 'Verkäuferportal ein',
    validCode: 'gültiger Code',
    invalidCode: 'ungültiger Code',
    textPlaceHolder: 'gib dein API Schlüssel hier ein',
    submitButtonText: 'Bestätige',
    newToScalapay: 'Neu in Scalapay',
    signupText: 'Registriere hier',
    signupUrl: 'https://www.scalapay.com/it/vendi-con-scalapay?utm_campaign=Integration%20Plugin&utm_source=integration&utm_medium=plugin'
  },
  es: {
    connectText: 'Conecta tu tienda a Scalapay',
    apiText: 'Introduce tu clave API desde la página de desarrolladores de tu',
    partnerPortalText: 'Portal de Socios',
    validCode: 'código válido',
    invalidCode: 'código incorrecto',
    textPlaceHolder: 'introduce tu clave API aquí',
    submitButtonText: 'confirmar',
    newToScalapay: 'Nuevo en Scalapay',
    signupText: 'Regístrate aquí',
    signupUrl: 'https://www.scalapay.com/es/vender-con-scalapay?utm_campaign=Integration%20Plugin&utm_source=integration&utm_medium=plugin'
  }
});

export default localization;
