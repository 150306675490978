import React, { useState } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, Typography, TextField, Link } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import scalapayLogo from './scalapay-logo.svg';
import SubmitButton from '../components/SubmitButton';
import { paymentApi } from '../services/shopifyPaymentApi';
import config from '../config';
import localization from '../localization';
import { Product } from 'src/types/api';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
  },
  apiKey: {
    fontWeight: 400,
    fontSize: '18px',
    marginBottom: '25px',
    width: '340px',
  },
  submitButton: {
    width: '365px',
    marginTop: '15px',
  },
  textNote: {
    fontWeight: '600',
    fontSize: '18px',
    width: '750px',
    marginBottom: '20px',
  },
  textHeaderNote: {
    marginTop: '150px',
    fontWeight: 700,
    fontSize: '28px',
    width: '750px',
    height: '28px',
    marginBottom: '20px'
  },
}));

const getQueryStringValue = (key: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params[key];
};

const getLanguage = () => {
  if (navigator.language.indexOf('fr-') >= 0) {
    localization.setLanguage('fr');
  } else if (navigator.language.indexOf('es-') >= 0) {
    localization.setLanguage('es');
  } else if (navigator.language.indexOf('de-') >= 0) {
    localization.setLanguage('de');
  } else if (navigator.language.indexOf('it-') >= 0) {
    localization.setLanguage('it');
  } else {
    localization.setLanguage('en');
  }
};

const LandingPage: FC = () => {
  const [apiKey, setApiKey] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [shopDomain] = useState<string>(getQueryStringValue('shop'));
  const [product] = useState<string>(getQueryStringValue('product'));
  const [appId] = useState<string>(getQueryStringValue('appId'));

  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubmit(false);
    setIsValid(false);
    setApiKey(event.target.value);
    setIsEdit(true);
  };

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmit(true);
    const response = await paymentApi.validateMerchantApiKey(apiKey, shopDomain, product as Product);
    if (response.status === 200) {
      setIsValid(true);
      window.location.href = appId ? `https://${shopDomain}/${config.shopifyPaymentGatewayRedirectPath}/${appId}` : `https://${shopDomain}/${config.shopifyPaymentGatewayRedirectPath}`;
    } else {
      setIsValid(false);
    }
    setIsEdit(false);
  };

  getLanguage();

  const tick = () => {
    if (isSubmit && !isEdit && isValid) {
      return <CheckIcon style={{ fill: 'green' }} />;
    }
    if (isSubmit && !isEdit && !isValid) {
      return <ErrorOutlineRoundedIcon style={{ fill: 'red' }} />;
    }
    return <div />;
  };

  return (
    <div>
      <header className="App-header">
        <img
          className="LandingPage-Logo"
          src={scalapayLogo}
          alt="Logo"
        />
      </header>
      <FormControl>
        <form onSubmit={handleSubmit}>
          <div className={classes.textHeaderNote}>
            <Typography
              variant="h4"
              align="center"
            >
              {localization.connectText}
            </Typography>
          </div>
          <div className={classes.textNote}>
            <Typography
              variant="h6"
              align="center"
            >
              {localization.apiText}
              {' '}
              <Link
                href={config.partnerPortalDevUrl}
                target="_blank"
              >
                {localization.partnerPortalText}
              </Link>
            </Typography>
          </div>
          <br />
          {isSubmit && !isEdit && (
          <div className={classes.textNote}>
            <Typography
              variant="caption"
              style={{ color: isValid ? 'green' : 'red' }}
              align="center"
            >
              {isValid ? localization.validCode : localization.invalidCode}
            </Typography>
          </div>
          )}
          <div className={classes.container}>
            <TextField
              className={classes.apiKey}
              id="outlined-name"
              variant="standard"
              placeholder={localization.textPlaceHolder}
              value={apiKey}
              onChange={handleChange}
            />
            {tick()}
          </div>
          <div className={classes.container}>
            <SubmitButton
              disabled={isSubmit}
            >
              {localization.submitButtonText}
            </SubmitButton>
          </div>
          <div className={classes.container}>
            <Typography
              variant="caption"
            >
              {localization.newToScalapay}
              ?
              {' '}
              <Link
                href={localization.signupUrl}
                target="_blank"
              >
                {localization.signupText}
              </Link>
            </Typography>
          </div>
        </form>
      </FormControl>
    </div>
  );
};

export default LandingPage;
